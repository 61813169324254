.lan_action_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F2FFFA;
    padding: 2rem 3rem 0 3rem;
    gap: 1rem;
}

.lan_action_section__buttons{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
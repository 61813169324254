.pre_msg_card{
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    min-width: 320px;
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0);
    text-align: center;
}

.pre_msg_card:hover{
    background-color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    cursor: pointer;
}

.pre_msg_card > h5, .pre_msg_card__active > h5{
    font-weight: 900;
    color: var(--text-clr);
}

.pre_msg_card__active{
    
    background-color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    border-radius: 10px;
    padding: 2%;
    min-width: 320px;
    
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.pre_msg_card__active:hover{
    cursor: pointer;
}




/* src/ChatInput.css */
.chat-input-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  background-color: #f1f1f1;
}


.chatbot_input_bar_new .google_address .input_google_address{
  border-radius: 0.5rem;
  padding-left: 8px;
  margin-bottom: 4px;
}

.chat-input-container textarea {
  flex: 1;
  padding: 1.19rem;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 0.5rem 0.5rem 0 0;
  resize: none;
  overflow: hidden;
  scrollbar-width: thin;
  min-height: 3.4375rem;
  /* Maximum height for the textarea */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  color: #3E3E3E;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  /* 150% */
}

.chat-input-container textarea:focus {
  outline: none;
}

.chat-input-container button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
}

.chat-input-container button:hover {
  background-color: #0056b3;
}

.chat-input-container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 48rem;
  height: 3.4375rem;
  flex-shrink: 0;
  background-color: #F3F3F3;
  padding: 1rem 1.12rem;
}

.preprompt_close{
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10%;
  top: 30%;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s;
  border-radius: 50%;
  color: var(--primary-dark-clr);
}

.preprompt_close:hover{
  background-color: var(--secondary-clr);
  cursor: pointer;
}

.chat-input-file-attach-area {
  display: flex;
  align-items: center;
  gap: 1em;
}

.chat-input-file-attach-area .or {
  color: #9B9B9B;
  font-size: 0.75rem;
  font-weight: 400;
}

.chat-input-file-attach-area h6 {
  margin-bottom: 0;
  color: #808080;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}

.chat_input_attachment {
  display: flex;
  gap: 0.375rem;
  align-items: center;
}

.chat_input_attachment .attach_text {
  color: #343541;
  font-size: 0.875rem;
  font-weight: 500;
}

.chat-input-container-bottom .send_button {
  background-color: var(--primary-clr);
  border-radius: 50%;
  border: none;
  width: 2.25rem;
  height: 2.25rem;
  flex-shrink: 0;
  transition: 1s;
}

.chat-input-container-bottom .send_button:hover{
  background-color: var(--primary-dark-clr);
}

.co_main_area__input_bar{
  margin-bottom: 2rem;
  padding: 0 1rem;
}

@media (max-width:768px){
  .co_main_area__input_bar{
    margin-bottom: 1rem;
  }

  .preprompt_close{
    right: 15%;
  }

  .chat-input-file-attach-area {
    gap: 0.5rem;
    padding-left: 4px;
  }

  .chat-input-file-attach-area .or {
    display: none;
  }
  
  .chat-input-file-attach-area h6 {
    display: none;
  }
  
  
  .chat_input_attachment .attach_text {
    font-size: 0.7rem;
  }
}
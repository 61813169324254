
.section-top {
    background-color: var(--primary-clr);
    padding: 3rem 2rem 0 3rem;
    font-family: "poppins", sans-serif;
}

.section_one {
    display: flex;
    margin-inline: auto;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}

.direction {
    flex-direction: row-reverse;
}

.section_one .texts {
    width: 60%;
}

.section_one .title {
    font-weight: 900;
    color: var(--text-light-clr);
}

.section_one .subtitle {
    color: var(--secondary-clr);
    font-weight: 600;
}

.section_one .paragraph {
    color: var(--text-light-clr);
}

.section_one .image {
    align-self: flex-end;
}

@media (max-width: 768px) {

    .section_one .image {
        display: none;
    }

    .section_one .texts {
        width: 100%;
    }

    .section-top{
        padding-bottom: 2rem;
    }




}

/* 
@media (max-width : 768px) {
  
    .section_one .texts{
        width: auto;
    }

    .section_one .image{
        min-width: 40%;
    }
    
  } */
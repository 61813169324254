.copilot_new {
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    overflow-x: hidden;

}

.copilot_new .copilot_new__main {
    display: flex;
}

.copilot_new__sidebar {
    width: 260px;
    height: calc(100vh - 66px);
    flex-shrink: 0;
}

.copilot_new__content {
    flex-grow: 1;
    height: calc(100vh - 66px);
}

.copilot_new__mobile_sidebar {
    display: none;
    transition: transform 0.5s ease;
    transform: translateX(-100%);
}


@media screen and (max-width: 768px) {
    .copilot_new__sidebar {
        display: none;
    }

    .copilot_new__mobile_sidebar {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999999;
        overflow-y: auto;
        height: 100vh;
    }

    .copilot_new__mobile_sidebar_active_mobile {
        transform: translateX(0);
    }
}
.ps_payment-schedule {
  margin-top: 2rem;
  max-width: 42rem;
}

.ps_title {
  color: #4C495A;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1.25rem;
}

.ps_plans {
  display: flex;
  flex-wrap: wrap;
  gap: 1.56rem;
}

.ps_plan {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 1.12rem;
  flex: 1;
  transition: border-color 0.3s;
  background-color: white;
  min-width: 20rem;
}

.ps_plan:hover {
  cursor: pointer;
  border-color: var(--primary-clr);
}

.ps_plan.selected {
  border-color: var(--primary-clr);
}

.ps_radio-button {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.ps_radio-button input[type="radio"] {
  margin-right: 0.5rem;
}

.ps_radio-button label {
  color: var(--primary-clr);
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.ps_price_area {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.ps_price {
  color: #3E3E3E;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.ps_price span {
  font-size: 0.875rem;
}

.ps_save {
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  height: 2.125rem;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: var(--primary-clr);
}

.ps_description {
  color: #9C9C9C;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
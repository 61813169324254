.billing_setting {
    height: 100vh;
}

.billing_setting h5 {
    color: #3E3E3E;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.billing_setting button {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    color: var(--Gray-White, #FFF);
    font-size: 1rem;
    font-weight: 500;
    transition: 0.5s;
}

.billing_setting button:hover {
    background-color: var(--primary-dark-clr);
}

.billing_setting__container {
    background-color: #F9F9F9;
    display: flex;
    gap: 1.5rem;
    padding: 1.87rem 6.25rem;
    min-height: calc(100% - 4.125rem);

}

.billing_setting__main {
    flex-grow: 1;
    overflow-y: auto;
}

.billing_setting__part {
    background-color: white;
    padding: 2rem 2rem 2.38rem 2rem;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
}

.billing_setting__profile {
    width: 6.125rem;
    height: 6.125rem;
    position: relative;
    margin-bottom: 1.87rem;
}

.billing_setting__profile .profile {
    border: 1px solid var(--primary-clr);
    padding: 4px;
    border-radius: 50%;
}

.billing_setting__profile .camera {
    position: absolute;
    right: 0;
    bottom: 0;
}

.billing_setting__profile .camera:hover {
    cursor: pointer;
}

.billing_setting__inputs {
    margin-bottom: 2rem;
}

.billing_setting__inputs_sets {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.my_subs {
    background-color: var(--primary-clr);
    border-radius: 0.5rem;
    padding: 1.2rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 16rem;
}

.my_subs__top {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.my_subs__plan_title {
    font-size: 1.5rem;
    font-weight: bold;
}

.cancel_subs_popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.cancel_subs_popup__container {
    position: absolute;
    background-color: white;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    min-height: 10rem;
}

.cancel_subs_popup__btns {
    display: flex;
    gap: 1rem;

}

@media only screen and (max-width: 992px) {
    

    .billing_setting__container {
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .billing_setting__inputs_sets {
        flex-direction: column;
    }

    .billing_setting__container{
        padding: 1rem;
    }
}
.countdown-timer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: #F70d1a;
  padding: 20px;
  border-radius: 10px;
  justify-content: space-evenly;
}

.countdown-timer__element {
  margin: 0 10px;
  display: flex;
}

.countdown-timer__time_and_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.countdown-timer__time_and_text .time {
  font-size: 3.5rem;
}

.countdown-timer__time_and_text .time_text {
  font-size: 0.9rem;
}

.countdown-timer .colon {
  font-size: 3.5rem;
  margin-left: 1rem;
  padding: 0 5px;
}

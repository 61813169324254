.chat_format table{
    width: 100%;
    border-collapse:separate;
    border-spacing: 0;
}

.chat_format th:first-child{
    border-radius:10px 0 0 0;
}

.chat_format th:last-child{
    border-radius:0 10px 0 0;
}

.chat_format th{
    background-color: rgb(226, 226, 226);
    color: rgb(70, 70, 70);
    padding: 8px 16px;
    text-align: center;
    border: 1px solid rgb(196, 196, 196);
}

.chat_format td{
    border: 1px solid rgb(196, 196, 196);
    padding: 8px 16px;
    color: rgb(70, 70, 70);
}

.chat_format ol{
    padding-inline-start: 4px;
}

.chat_format ol > li{
    margin-bottom: 2rem;
}

@media (max-width:768px){
    .chat_format th{
        padding: 4px;
        font-size: 0.8rem;
    }

    .chat_format table{
        width: 100%;
        overflow-x: auto;
    }
}
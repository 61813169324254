.plans_and_comparison {
  padding: 0 24px;
}

.plans,
.comparison {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  max-width: 1068px;
}

.plans__heading,
.comparison__heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-top: 4rem;
}

.toggle {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto;
}

.toggle__label {
  line-height: 22px;
  font-size: 1.5rem;
}

.plans__container {
  display: grid;
  justify-content: center;
  grid-auto-columns: 33%;
  gap: 24px;
  grid-auto-flow: column;
}

.plan {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 18px;
  padding: 20px;
  min-width: 20rem;
}

.plan__content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dedede;
}

.plan__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.plan__info__name {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
  color: #3e3e3e;
}

.plan__info__description {
  font-size: 16px;
  line-height: 24px;
  color: #9c9c9c;
}

.plan__features {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.plan__feature {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan__feature__icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(18, 99, 66, 0.1);
}

.plan__feature__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #3e3e3e;
}

.plan__price {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;
}

.plan__price_heading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan__price_heading__amount {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #126342;
}

.plan__price_heading__duration {
  font-size: 12px;
  line-height: 24px;
  color: #9c9c9c;
}

.plan__price_detail {
  line-height: 23px;
  color: #3e3e3e;
}

.plan__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  background-color: #126342;
  border: none;
  text-decoration: none;
  transition: 0.5s;
}

.plan__link:hover {
  background-color: var(--primary-dark-clr);
}

.comparison__table {
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
}

.table__header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 10px;
  min-height: 48px;
  background-color: rgba(18, 99, 66, 0.03);
  border-radius: 12px 12px 0 0;
  padding: 10px 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  color: #126342;
}

.table__first_col {
  grid-column: span 3;
}

.table__row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 10px;
  min-height: 50px;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  line-height: 22px;
  padding: 10px 20px;
}

.table__cell {
  text-align: center;
}

.table__row:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: none;
}

.comparison__container {
  padding-bottom: 3rem;
}

@media (max-width: 1024px) {
  .plans__container {
    grid-auto-columns: 50%;
  }
}

@media (max-width: 600px) {
  .plans__container {
    grid-auto-columns: 100%;
  }

  .table__header,
  .table__row {
    grid-template-columns: repeat(3, 1fr);
  }

  .table__first_col {
    grid-column: span 2;
  }
}

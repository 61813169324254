.terms_and_conditions {
    color: var(--text-clr);
    font-family: "poppins", sans-serif;
}

.terms_and_conditions .terms_title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 2rem 4rem;
    background-color: var(--primary-clr);
    color: var(--secondary-clr);
}

.terms_and_conditions .term_items {
    padding: 1rem 4rem;
}

/* .terms_and_conditions .term_item{
    padding: 1rem;
}

.terms_and_conditions .term_item_title{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--primary-clr);
    
} */

.terms_and_conditions h3 {
    margin-bottom: 1rem;
    color: var(--primary-clr);
    font-weight: 500;
    margin-top: 2rem;
}

.terms_and_conditions p {
    margin-bottom: 2rem;
}

.terms_and_conditions ul {
    margin-bottom: 1rem;
}

@media (max-width:768px) {
    .terms_and_conditions .terms_title {
        padding: 1rem;
        font-size: 2rem;
        margin-bottom: 0;
    }
    .terms_and_conditions .term_items {
        padding: 1rem;
    }
}
.copilot_tulsa_new_chat__logo_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.copilot_tulsa_new_chat__logo_area img {
    max-width: 4rem;
}

.copilot_tulsa_new_chat__logo_area h3 {
    color: var(--primary-clr);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
}

.copilot_tulsa_new_chat__logo_area .title{
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.15rem;
    text-align: center;
}

.copilot_tulsa_new_chat_instruction{
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0 0 0.8rem 0.8rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.copilot_tulsa_new_chat_bot{
    display: flex;
    gap: 1rem;
    
}

.copilot_tulsa_new_chat_bot_logo{
    border-radius: 50% 0 50% 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 4px;
}



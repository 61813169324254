.gm-style-iw-chr{
    display: none;
}


.info_parcel{
    position: absolute;
    top: 2%;
    left: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    font-family: "poppins";
    border-radius: 0.3rem;
}

.info_parcel h6{
    margin-bottom: 0;
    color: white;
}
  
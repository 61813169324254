.chat_source{
    display: flex;
    gap:16px;
    align-items: center;
}

.chat_source .sources{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.chat_source .source{
    background-color: var(--secondary-clr);
    padding: 2px 16px;
    border-radius: 14px;
    text-decoration: none;
    color: white;
    transition: 0.5s;
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat_source .source:hover{
    background-color: var(--secondary-dark-clr);
}
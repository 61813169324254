.co_main_area{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 4.125rem);
    overflow-x: hidden;
}

.co_main_area_chat_container{
    overflow-y: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    scrollbar-width: thin;
    margin-bottom: 1rem;
    padding-top: 2rem;
}

.co_main_area__chat_area{
    flex-grow: 1;
    padding-left: 0.5rem;
}

.co_main_area__input_bar{
    width: 100%;
    max-width: 48rem;

}

@media (max-width: 992px) {
    .co_main_area__input_bar {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (max-width: 600px) {
    .co_main_area__chat_area{
        padding-left: 0 !important;
    }
  }
.dashboard_new {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    background-color: #F9F9F9;
    position: relative;
}

.dashboard_new .btn_load_more{
    width: 3rem;
    height: 3rem;
    background-color: var(--primary-clr);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: 0.5s;
}

.dashboard_new .btn_load_more:hover{
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.dashboard_new .btn_load_more_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_new .dashboard_new__main {
    margin: 1rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.dashboard_new .dashboard_new__top_filter_area {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.dashboard_new .dashboard_new__top_accu{
    position: relative;
}
/* .dashboard_new__filter_min_max{
    background-color: white;
    position: absolute;
    padding: 1rem;
    left: -50%;
    top: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    z-index: 9;
    display: flex;
    flex-direction: column;
}

.dashboard_new__min_max__inputs {
    display: flex;
    gap: 0.81rem;
    align-items: center;
}

.dashboard_new__min_max__values {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #84818A;
    font-weight: 500;
    margin-bottom: 0.94rem;
}

.dashboard_new__min_max__label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
}

.dashboard_new__min_max__inputs input{
    width: 6ch;
} */

.dashboard_new .dashboard_new__address_input {
    display: flex;
    align-items: center;
    flex-grow: 4;
}

.dashboard_new .dashboard_new__address_input .keyword_search_btn{
    
    border-radius: 0rem 0.3125rem 0.3125rem 0rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.dashboard_new .dashboard_new__address_input .keyword_search_btn:hover {
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.dashboard_new .dashboard_new__address_input .google_address{
    width: 100%;
}


.dashboard_new .dashboard_new__address_input h5{
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 400;
}



.dashboard_new .dashboard_new__address_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard_new .dashboard_new_address {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
}

.dashboard_new .dashboard_new__actions_buttons{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.dashboard_new .dashboard_new__actions_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    width: 6.8rem;
    background-color: var(--primary-clr);
    padding: 0.75rem 1.125rem;
    border-radius: 0.375rem;
    color: white;
    transition: 0.5s;
    font-size: 0.875rem;
    font-weight: 500;
    position: relative;
}

.dashboard_new .dashboard_new__actions_button:hover {
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.dashboard_new .metric_cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;

}


/* Plans found area */
.dashboard_new .dashboard_new__plans_area {
    width: 100%;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0.05rem solid rgba(18, 99, 66, 0.5);
}

.dashboard_new .dashboard_new__title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
}

.dashboard_new .dashboard_new__plans {
    display: flex;
    gap: 1.5rem;
    overflow-y: hidden;
    overflow-x: auto;
    height: 21.5rem;
    max-width: calc(19rem * 4);
    transition: 0.5s;
    scrollbar-width: thin;
}


/* parcel area */
.dashboard_new .dashboard_new__parcel_area {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.dashboard_new .dashboard_new__parcel_map {
    flex: 1;
    flex-grow: 1;
    border-radius: 1rem;
    padding: 2rem 2rem 4rem 2rem;
    background-color: white;
    min-height: 18rem;
    min-width: 20rem;
    border: 0.05rem solid rgba(18, 99, 66, 0.5);
}

.dashboard_new .dashboard_new__parcel_map h6 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
}

.dashboard_new .dashboard_new__parcel_details {
    flex-grow: 1;
    flex: 1;
    padding: 1rem 2rem 4rem 2rem;
    border-radius: 1rem;
    background-color: white;
    /* min-height: 18rem; */
    min-width: 20rem;
    
    border: 0.05rem solid rgba(18, 99, 66, 0.5);
}

/* comparable sales */
.dashboard_new .dashboard_new__comparable_sales {
    width: 100%;
    margin-bottom: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    border: 0.05rem solid rgba(18, 99, 66, 0.5);
}

.dashboard_new .dashboard_new__comparable_sales h6 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1rem;
}

.dashboard_new .filter_bar {
    position: fixed;
    right: 0;
    top: 64px;
    overflow-y: auto;
    height: calc(100vh - 64px);
    z-index: 9999;
    transition: 0.5s;
    scrollbar-width: thin;
}



.dashboard_new .filter_overflow {
    position: fixed;
    left: 0;
    top: 64px;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background-color: #27272733;
}

.dashboard_new .dashboard_new__top_filter{
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    justify-content: space-between;
}

.dashboard_new .dashboard_new__more_filter_btn {
    display: flex;
    height: 2.5rem;
    padding: 0.59375rem 0.9375rem 0.59375rem 0.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.375rem;
    border: 1px solid #626262;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    transition: 0.5s;
    flex-shrink: 0;
    text-wrap: nowrap;
}

.dashboard_new .dashboard_new__more_filter_btn:hover{
    background-color: var(--primary-clr);
    color: white;
    cursor: pointer;
}

.dashboard_new .dashboard_new__more_filter_drop {
    display: flex;
    height: 2.5rem;
    padding: 0.59375rem 0.9375rem 0.59375rem 0.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.2rem;
    border: 1px solid rgba(0, 0, 0, 0.288);
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    transition: 0.5s;
    flex-shrink: 0;
    text-wrap: nowrap;
    padding-left: 2rem;
}

.dashboard_new .dashboard_new__more_filter_drop:hover{
    cursor: pointer;
    border: 1px solid rgb(0, 0, 0);
}

.dashboard_new .dashboard_new__more_filter_drop:focus{
    
    border: 1px solid rgb(0, 0, 0);
}

.dashboard_new .construction_tooltop td{
    text-wrap: nowrap;
    border: 1px solid;
    text-align: center;
}

.dashboard_new .construction_tooltop th{
    text-wrap: nowrap;
    border: 1px solid;
}

@media only screen and (max-width: 992px) {
    
    .dashboard_new .dashboard_new__parcel_map {
        min-width: 100%;
    }

    .dashboard_new .dashboard_new__parcel_details{
        min-width: 100%;
    }



}

@media only screen and (max-width: 767px) {
    .dashboard_new .dashboard_new__main {
        margin: 1rem;
    }

    .dashboard_new .dashboard_new__plans_area {
        width: 100%;
        padding: 0.5rem;
        border-radius: 1rem;
        background-color: #fff;
    }

    .dashboard_new .dashboard_new__plans {
        overflow-x: auto;
        height: 24rem;
        transition: 0.5s;
    }

    .dashboard_new .dashboard_new__parcel_map {
        height: auto;
        padding: 2rem 1rem;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .dashboard_new .dashboard_new__top_filter{
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .dashboard_new .dashboard_new__address_area{
        flex-wrap: wrap;
    }

    .dashboard_new .dashboard_new_address{
        text-align: center;
        width: 100%;
    }
    .dashboard_new .dashboard_new__actions_buttons{
        width: 100%;
        justify-content: space-between;
    }

    

}

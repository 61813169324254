.IQList {
    position: absolute;
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    right: 0.5rem;
    border-radius: 0.5rem;
    list-style-type: none;
    padding: 0.5rem 0;
    z-index: 99999;
}

.IQList li{
    background-color: white;
    transition: 0.5s;
    padding: 0.5rem 0.8rem;
    font-size: 0.875rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.IQList li:hover{
    cursor: pointer;
    background-color: var(--primary-clr);
    color: white;
}
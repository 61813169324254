.iq_chat_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iq_chat_box > input{
    flex-grow: 1;
    min-height: 48px;
    max-width: 60%;
    min-width: 300px;
    border: none;
    padding: calc(0.5% + 4px) calc(1% + 8px);
    border-radius: 10px 0 0 10px;
    outline: none;
    border-style: none;
    border-width: 0 0 0 0;
    border-color: var(--primary-clr);
    transition: 1s;
}

.iq_chat_box > input:focus{
    border-style: solid;
    border-width: 1px 0 1px 1px;
}

.iq_chat_box > button{
    min-height: 48px;
    min-width: 48px;
    padding: 0.5% 1%;
    border: none;
    background-color: var(--primary-clr);
    color: white;
    border-radius: 0 10px 10px 0;
    transition: 0.5s;

    display: flex;
    align-items: center;
    justify-content: center;
}

.iq_chat_box > button:hover{
    background-color: var(--primary-dark-clr);
}

.iq_chat_box > button > img{
    width: 50%;
    min-width: 24px;
}


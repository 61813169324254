.parcel_details {
    flex-grow: 1;
    flex: 1;
    flex-shrink: 0;
    height: 26rem;
}

.parcel_details__titles {
    display: flex;
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;
}

.parcel_details__titles h6 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    color: #3E3E3E;
    align-self: center;
    height: 2rem;

}

.parcel_details__titles h6:hover{
    cursor: pointer;
    color: var(--primary-light-clr); 
}

.accordian_title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    color: #3E3E3E;
    align-self: center;
    height: 2rem;

}

.parcel_information{
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    padding: 1.25rem 0;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    height: 24rem;
}

.parcel_information__item{
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 0 0 0.0625rem 0 ;
    border-color: #EBEBEB;
    align-items: center;
    
    padding: 0 1.25rem;

}

.parcel_information__title_area img{
    margin-bottom: 0.62rem;
    margin-top: 0.62rem;
}

.parcel_information__title_area{
    display: flex;
    gap: 0.81rem;
    align-items: center;
}

/* @media only screen and(max-width: 1024px){
    .parcel_details {
        width: 100%;
        height: 26rem;
    }
    
    
    .parcel_information{
        display: flex;
        flex-direction: column;
        gap: 0.62rem;
        padding: 1.25rem;
        background-color: white;
    }
    
    .parcel_information__item{
        display: flex;
        justify-content: space-between;
        border-style: solid;
        border-width: 0 0 0.0625rem 0 ;
        border-color: #EBEBEB;
        align-items: center;
    
    }
    
    .parcel_information__title_area img{
        margin-bottom: 0.62rem;
        margin-top: 0.62rem;
    }
    
    .parcel_information__title_area{
        display: flex;
        gap: 0.81rem;
        align-items: center;
    }
} */


/* @media only screen and (max-width: 768px) {
    .parcel_details {
        min-width: 100%;
        height: 26rem;
    }
    
    .parcel_information{
        display: flex;
        flex-direction: column;
        gap: 0.62rem;
        padding: 1.25rem;
        background-color: white;
    }
    
    .parcel_information__item{
        display: flex;
        justify-content: space-between;
        border-style: solid;
        border-width: 0 0 0.0625rem 0 ;
        border-color: #EBEBEB;
        align-items: center;
    
    }
    
    .parcel_information__title_area img{
        margin-bottom: 0.62rem;
        margin-top: 0.62rem;
    }
    
    .parcel_information__title_area{
        display: flex;
        gap: 0.81rem;
        align-items: center;
    }
} */


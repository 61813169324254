.hero-container {
    background-color: #effff8;
    position: relative;
    min-height: 20rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;

}

.left-column {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);

}


.left-column h1 {
    color: var(--secondary-clr);
    font-family: "poppins", sans-serif;
    font-size: 2.5rem;
    font-weight: 900;
    animation: fadeIn 2s steps(20, end) forwards;
}

.left-column p {
    color: var(--primary-clr);
    font-family: "poppins", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    animation: fadeIn 2s steps(5, end) forwards;
    opacity: 0;
    min-height: 5rem;
    padding-right: 1rem;
}


.left-column p::after {
    content: "O";
    margin: 1rem;
    width: 10px;
    height: 10px;
    border-radius: 2rem;
    background-color: var(--primary-clr);
    left: calc(100% + 5px);
    top: calc(50%);
    animation: blinkCursor 0.3s infinite alternate;
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.typed-text {
    position: relative;
}


@keyframes blinkCursor {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}



@media only screen and (max-width: 600px) {

    .hero-container{
        padding: 2rem;
    }

    .left-column h1 {
        margin-top: 0vh !important;
        font-size: 1.5rem !important;
    }

    .left-column p {
        font-size: 1.1rem !important;
    }

}

@media only screen and (max-width: 1100px) {

    .left-column h1 {
        font-size: 2.5rem;
    }

    .left-column p {
        font-size: 1.5rem;
    }


}
.filter_msg_pop_container{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
}

.filter_msg_pop{
    position: relative;
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
}

.filter_msg_pop_title{
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
}

.filter_msg_pop_img{
    width: 10rem;
    align-self: center;
}

.filter_msg_pop .filter_msg_pop_close{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-weight: 300;
    transition: 0.5s;
    padding: 0.5rem;
    height: 3ch;
    width: 3ch;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.filter_msg_pop .filter_msg_pop_close:hover{
    cursor: pointer;
    background-color: var(--secondary-clr);
}

.filter_msg_pop .filter_msg_pop_msg{
    padding-bottom: 1rem;
}

.filter_msg_pop .filter_msg_pop_dont{
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.filter_msg_pop_button{
    width: 100%;
}

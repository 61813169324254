.lan_dashboard {
    overflow-x: hidden;
}

.lan_dashboard_why_texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.lan_dashboard_plan {
    display: flex;
    justify-content: center;
    background-color: var(--primary-clr);
}

.lan_dashboard_plan_left {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    gap: 1rem;
}

.lan_dashboard_plan_right {
    background-color: var(--primary-dark-clr);
    padding: 2rem 2rem 0 2rem;
    display: flex;
}

.lan_dashboard_plan_right img{
    align-self: flex-end;
    object-fit: cover;
}

.lan_dashboard_plan_left_acc {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.lan_dashboard__our_stats {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.lan_dashboard__our_stats_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

}

.lan_dashboard__our_stats_card {
    max-width: 20rem;
    min-width: 10rem;
    flex: 1;
}

.lan_state_big_num {
    color: var(--secondary-clr);
    font-size: 4.125rem;
    font-weight: 500;
}

.lan_state_bottom_text {
    color: var(--primary-clr);
    font-size: 1.375rem;
    font-weight: 500;
}

.lan_dashboard__transform {
    background-color: var(--primary-clr);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    background-image: url("/public/images/lan_home/join_iqland_back.png");
    background-position: 0%;
    background-repeat: no-repeat;
    object-fit: cover;
}

@media (max-width: 767.98px) {
    .lan_dashboard_plan {
        flex-direction: column;
    }
}
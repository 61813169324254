.lan_developer_why_iqland .lan_home__why_iqland_cards{
    align-items: baseline;
}

.lan_architect__why_iqland_card{
    align-self: stretch;
}

@media (max-width:768px){
    .lan_developer_item{
        flex-direction: column;
    }

    .lan_developer_why_iqland{
        padding: 2rem;
    }
}
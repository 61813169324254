.personal_info {
    height: 100vh;
}

.personal_info h5 {
    color: #3E3E3E;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.personal_info button {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    color: var(--Gray-White, #FFF);
    font-size: 1rem;
    font-weight: 500;
    transition: 0.5s;
}

.personal_info button:hover {
    background-color: var(--primary-dark-clr);
}

.personal_info__container {
    background-color: #F9F9F9;
    display: flex;
    gap: 1.5rem;
    padding: 1.87rem 6.25rem;
    min-height: calc(100% - 4.125rem);
}

.personal_info__main {
    flex-grow: 1;
    overflow-y: auto;
}

.personal_info__part {
    background-color: white;
    padding: 2rem 2rem 2.38rem 2rem;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
}

.personal_info__profile {
    width: 6.125rem;
    height: 6.125rem;
    position: relative;
    margin-bottom: 1.87rem;
}

.personal_info__profile .profile_img{
    width: 6.125rem;
    height: 6.125rem;
    border-radius: 100%;
    object-fit: cover;
}

.personal_info__profile .profile_img_actions{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.125rem;
}

.personal_info__profile .profile_img_actions .profile_ok{
    background-color: var(--primary-clr);
    border-radius: 50%;
    padding:0.2rem;
    color:white;
    margin-right: 4px;
    transition: 0.5s;
}
.personal_info__profile .profile_img_actions .profile_close{
    background-color: red;
    border-radius: 50%;
    padding:0.2rem;
    color:white;
    transition: 0.5s;

}

.personal_info__profile .profile_img_actions .profile_ok:hover{
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.personal_info__profile .profile_img_actions .profile_close:hover{
    background-color: rgb(153, 0, 0);
    cursor: pointer;
}

.personal_info__profile .profile{
    border: 1px solid var(--primary-clr);
    padding: 4px;
    border-radius: 50%;
}

.personal_info__profile .camera{
    position: absolute;
    right: 0;
    bottom: 0;
}

.personal_info__profile .camera:hover{
    cursor: pointer;
}

.personal_info__inputs{
    margin-bottom: 2rem;
}

.personal_info__inputs_sets{
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 992px) {
    

    .personal_info__container {
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .personal_info__inputs_sets {
        flex-direction: column;
    }

    .personal_info__container{
        padding: 1rem;
    }
}
.favorites {
    background-color: #F9F9F9;
}

.favorites__body{
    padding: 1.56rem 7rem;
}

.favorites__container{
    background-color: white;
    padding: 1.8rem 2rem;
    border-radius: 0.5rem;
}

.favorites__title{
    display: flex;
    gap: 4px;
}

.favorites__title h6{
    color: #171721;
    font-size: 1.25rem;
    font-weight: 600;
}

.favorites__title span{
    color: #9C9C9C;
    font-size: 1.25rem;
    font-weight: 600;
}

.favorites__plans{
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}
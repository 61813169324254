.tulsa_landing {
    font-family: "poppins", sans-serif;
    overflow-x: hidden;
}

.tulsa_landing .tl_hero_page {
    display: flex;
    align-items: center;
    background-color: var(--primary-clr);
    padding: 3rem 1rem 0 4rem;

}

.tulsa_landing .tl_hero_left {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tulsa_landing .tl_hero_right {
    display: flex;
    align-self: flex-end;

}


.tulsa_landing .tl_title {
    font-size: 3rem;
    font-weight: 700;
    color: white;
    margin-bottom: 1rem;
}

.tulsa_landing .tl_subtitle {
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-clr);
    margin-bottom: 1rem;

}

.tulsa_landing .tl_trial_text {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}


.tulsa_landing .tl_iqland_tool {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #F2FFFA;
    padding: 3rem 4rem 3rem 0;

}

.tulsa_landing .tl_iqland_tool_left {
    flex: 1;
    display: flex;
    flex-shrink: 1;
    max-width: 50%;
}

.tulsa_landing .tl_iqland_tool .tl_title {
    color: var(--primary-clr);
}

.tulsa_landing .tl_iqland_tool_right {
    flex: 3;
    min-width: 18rem;
}


.tulsa_landing .tl_steps {
    background-color: white;
    padding: 3rem 0;
}

.tulsa_landing .tl_steps .tl_title {
    color: var(--secondary-clr);
    text-align: center;
    margin-bottom: 3rem;
}

.tulsa_landing .tl_steps_cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tulsa_landing .tl_step_item {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.tulsa_landing .tl_step_card {
    background-color: var(--primary-clr);
    flex: 1;
    padding: 2rem;
    border-radius: 0 20rem 20rem 0;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 2rem;
    width: 20vw;


}

.tulsa_landing .tl_step_card2 {
    background-color: var(--primary-clr);
    flex: 1;
    padding: 2rem;
    border-radius: 0 20rem 20rem 0;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2rem;
    width: 20vw;


}

.tulsa_landing .tl_step_card img {
    max-width: 20vw;
    border-radius: 0 20rem 20rem 0;
}

.tulsa_landing .tl_step_card2 img {
    max-width: 20vw;
    border-radius: 20rem 0 0 20rem;
}

.tulsa_landing .tl_step_card_title_area {
    display: flex;
    gap: 1rem;
    padding: 0 3rem;
    width: 50%;
}

.tulsa_landing .tl_step_card_number {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 64px;
    height: 64px;
    font-size: 2rem;
    font-weight: 700;
    background-color: var(--secondary-clr);
    border-radius: 50%;
    flex-shrink: 0;
    color: #303030;
}

/* .tulsa_landing .tl_step_card_texts{

} */
.tulsa_landing .tl_step_card_text_title {
    font-size: 1.5rem;
    font-weight: 700;
}

.tulsa_landing .tl_step_card_text_normal {
    font-weight: 400;
}

/* .tulsa_landing .tl_step_card_img{

} */

.tulsa_landing .tl_demo_copilot {
    background-color: rgb(255, 255, 255);
    padding: 4rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tulsa_landing .tl_demo_copilot .tl_title {
    color: var(--primary-clr);
    text-align: center;
}

.tulsa_landing .tl_demo_container {
    background: linear-gradient(to bottom right, #181818, #303030);
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.3);

}

.tulsa_landing .tl_demo_container .tl_demo_window {
    background-color: rgb(255, 255, 255);
    min-height: 10rem;
    width: 100%;
    border-radius: 4px;
    border-style: solid;
    border-width: 2;
    border-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.tulsa_landing .tl_demo_copilot_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tulsa_landing .tl_demo_copilot_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 18rem;
}

.tulsa_landing .tl_demo_container .tl_demo_window .demo_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    padding: 2rem 1rem;
}

.tulsa_landing .tl_demo_copilot_right_item {
    margin-bottom: 2rem;
}

.tulsa_landing .tl_demo_copilot_right_title {
    color: var(--secondary-clr);
    font-size: 1.2rem;
    font-weight: 500;
}

.tulsa_landing .tl_demo_copilot_right_text {
    color: #303030;
}

.tulsa_landing .join_iqland_padding {
    padding: 4rem;
}

.tulsa_landing .tulsa_send_button_area {
    position: relative;
}

.tulsa_landing .tulsa_send_button_press_me {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20rem;
    border: solid 2px var(--primary-clr);
    padding: 0.4rem;
    height: calc(4rem + 2px);
    width: calc(4rem + 2px);
    animation: blink 1s infinite;
    cursor: pointer;
}
.tulsa_send_button_press_me_text{
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-wrap: nowrap;
    color: var(--primary-clr);
    font-weight: 500;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
@media (max-width: 768px) {
    .tulsa_landing .tl_hero_page {
        padding: 2rem 2rem 0 2rem;
        flex-direction: column;
    }

    .tulsa_landing .tl_hero_left {
        text-align: center;
        align-items: center;
    }

    .tulsa_landing .tl_title {
        font-size: 2rem;
    }

    .tulsa_landing .tl_subtitle {
        font-size: 1.5rem;

    }

    .tulsa_landing .tl_trial_text {
        font-size: 1rem;
    }

    .tulsa_landing .tl_demo_copilot {
        padding: 2rem;
    }

    .tulsa_landing .tl_demo_copilot .tl_title {
        color: var(--primary-clr);
        text-align: center;
    }

    .tulsa_landing .tl_demo_container {
        padding: 1rem;

    }

    .tulsa_landing .tl_demo_container .tl_demo_window {
        padding: 1rem;
    }

    .tulsa_landing .tl_demo_copilot_right {
        min-width: 100%;
    }

    .tulsa_landing .tl_demo_copilot_content {
        flex-direction: column;
        gap: 1rem;
    }

    .tulsa_landing .tl_iqland_tool {
        flex-direction: column-reverse;
        padding: 1rem 1rem 1rem 0;
    }

    .tulsa_landing .tl_iqland_tool_right {
        padding-left: 1rem;
        text-align: center;
    }

    .tulsa_landing .tl_iqland_tool_left {
        max-width: 100%;
        align-self: self-start;
    }

    .tulsa_landing .tl_steps {
        padding: 2rem 0;
    }

    .tulsa_landing .tl_steps .tl_title {
        margin-bottom: 2rem;
    }


    .tulsa_landing .tl_step_item {
        gap: 1rem;
    }

    .tulsa_landing .tl_step_card {
        justify-content: left;
    }

    .tulsa_landing .tl_step_card2 {
        justify-content: right;
    }

    .tulsa_landing .tl_step_card_title_area {
        display: flex;
        gap: 1rem;
        padding: 0 2rem;
        width: 100%;
    }

    .tulsa_landing .tl_step_card_number {
        width: 48px;
        height: 48px;
        font-size: 1.5rem;
    }
}
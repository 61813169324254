.co_sidebar {
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    border-style: solid;
    border-color: #82828229;
    border-width: 0 1px 0 0;
    padding: 16px 12px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    transition: 1s;
}


.copilot_sidebar__new_chat_container{
    border-radius: 4px;
    transition: 0.5s;
}

.copilot_sidebar__new_chat_container:hover{
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.copilot_sidebar__new_chat {
    font-weight: 400;
    font-size: 0.875rem;
    width: 14.5rem;
    height: 3rem;
    padding-left: 0.625rem;
    padding-right: 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    transition: 0.5s;
    border-radius: 4px;
}


.copilot_sidebar__new_chat .new_chat_icon {
    color: #566B76;

}


.copilot_sidebar__new_chat h4 {
    font-size: 1rem;
    color: #3e3e3e;
    margin-bottom: 0;
}

.co_sidebar__chat_history_area h5 {
    color: #566B76;
    font-size: 0.75rem;
    padding-left: 8px;
    margin-top: 1.25rem;
    font-weight: bold;
}

.co_sidebar__chat_history {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
}

.co_sidebar__chat_history li {
    font-weight: 400;
    font-size: 0.875rem;
    width: 14.5rem;
    height: 2.5rem;
    padding-left: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
}

.co_sidebar__chat_history .active {

    border-radius: 0.3125rem;
    border-left: 2px solid #09814A;
    background: #E7E7E7;
    transition: 0.5s;
}

.co_sidebar__chat_history li:hover {
    border-radius: 0.3125rem;
    border-left: 2px solid #09814A;
    background: #E7E7E7;
    cursor: pointer;
}

.co_sidebar__chat_history li .more_icon {
    opacity: 1;
    width: 1.375rem;
    height: 1.375rem;
    color: #566B76;
    flex-shrink: 0;
}
.co_sidebar__mobile{
    display: none;
}

@media screen and (max-width: 768px) {
    .co_sidebar__mobile{
        display: block;
    }

    .co_sidebar__mobile ul{
        display: flex;
        flex-direction: column;
        list-style: none;
        gap: 1rem;
        padding-left: 0.625rem;
    }

    .co_sidebar__mobile li:hover{
        cursor: pointer;
        color: var(--primary-clr);
    }
}
.co_single_chat {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* user chat */
.co_single_chat__user_chat {
    display: flex;
    /* flex-direction: column; */
    padding: 1.63rem;
}

.co_single_chat__user_profile {
    display: flex;
    /* align-items: center; */
    gap: 8px;
    flex-shrink: 0;
}

.co_single_chat__user_profile img {
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    border-color: var(--primary-clr);
    object-fit: cover;
}

.co_single_chat__user_profile h5 {
    font-weight: bold;
    columns: var(--text-clr);
}

.co_single_chat__user_message {
    /* padding-left: 46px;
    margin-top: -10px; */
    margin-left: 16px;
    margin-top: 8px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}


/* bot chat */
.co_single_chat__bot_chat {
    display: flex;
    /* flex-direction: column; */
    border-radius: 1rem;
    background: rgba(18, 99, 66, 0.03);
    padding: 1.63rem;
    width: 100%;
    overflow: hidden;
}

.co_single_chat__bot_profile {
    /* display: flex; */
    /* align-items: center; */
    /* gap: 4px; */
    flex-shrink: 0;
}

.co_single_chat__bot_profile img {
    width: 36px;
}

.co_single_chat__bot_profile h5 {
    font-weight: bold;
    columns: var(--text-clr);
}

.co_single_chat__bot_message {

    /* padding-left: 46px; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* padding-top: 16px; */
    /* padding-right: 16px; */
    margin-left: 16px;
    font-size: 0.9rem;
    font-weight: 400;
    width: 90%;
}

.co_single_chat__answer_area {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.co_single_chat__answer_icon_area {
    display: flex;
    gap: 8px;
    color: var(--primary-clr);
}

.co_single_chat__answer_icon_area h6 {
    font-weight: bold;
}

.co_single_chat__answer_text {
    text-align: left;
}

.co_single_chat__sources {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.co_single_chat__source {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 36px;
    padding: 8px 12px;
    background-color: #F2FFF0;
    border-radius: 10px;
    transition: 1s;

}


.co_single_chat__source h6 {
    font-size: small;
    padding-top: 4px;
}

.co_single_chat__source h6:hover {
    color: var(--primary-clr);
    cursor: pointer;
}

.co_single_chat__populate_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 36px;
    background-color: #F2FFF0;
    border-radius: 6px;
    width: 160px;
    margin-top: 8px;
    align-self: flex-start;
    transition: 0.5s;
    margin-bottom: 16px;
}

.co_single_chat__map{
    width: 100%;
    height: 20rem;
}

.co_single_chat__populate_button:hover {
    background-color: var(--primary-clr);
    color: white;
    cursor: pointer;
}

.co_single_chat__populate_button h6 {
    padding-top: 4px;
    font-size: small;
}

.co_single_chat__chat_list {
    margin-top: 16px;
    list-style-type: none;
    padding-left: 0;
    overflow: hidden;

}

.co_single_chat__chat_list li {
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 16px;
    height: 32px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.co_single_chat__chat_list li:hover {
    background-color: #ddffd8;
    border-radius: 4px;
}

.copilot_new__plans{
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    padding: 1rem 0;
    width: 100%;
}

@media (max-width: 767.98px) {
    .copilot_new__plans{
        max-width:calc(100vw - 6rem);
    }

    .copilot_new__plans .plan_new{
        width:16rem;
    }

}

@media (max-width: 600px) {
    .co_single_chat__bot_chat{
        padding: 1rem;
    }
}
.iq_primary_btn {
    background-color: var(--primary-clr);
    width: 100%;
    display: flex;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    color: white;
    transition: 0.5s;
    border-radius: 0.375rem;
    border: none;
}

.iq_primary_btn:hover{
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

@media (min-width: 1920px) {
    html {
        font-size: 18px; /* Adjust for large screens like Apple monitors */
    }
}
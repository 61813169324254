.municipality_demo_tutorial {
    background-color: white;
    border-radius: 2px;
    padding: 4% 6%;
    max-width: 800px;
    min-width: 360px;
    height: 85vh;
    max-height: 600px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}



.municipality_demo_tutorial__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: justify;
}

.municipality_demo_tutorial__content h4{
    font-weight: bold;
    color: var(--primary-clr);
    font-size: 150%;
}

.municipality_demo_tutorial__content h3{
    font-weight: bold;
}

.municipality_demo_tutorial__content p{
    font-size: 80%;
    margin-bottom: 0;
}

.municipality_demo_tutorial__buttons{
    display: flex;
    justify-content: space-between;
    gap: 10%;
    width: 90%;
}

.municipality_demo_tutorial__content img{
    width: 75%;
    border: 1px solid var(--primary-clr);
    margin-bottom: calc(4px + 0.5%);

}

/* small devices like mobile */
@media (max-width:576px) {
    .municipality_demo_tutorial{
        transform: scale(0.8);
        height: 100vh;

        max-height: 500px;
    }

    .municipality_demo_tutorial img{
        transform: scale(0.8);
    }

    .municipality_demo_tutorial h4{
        font-size: 1rem;
    }
}


.report_page{
    padding: 1rem 3rem;
}

.report_page__back{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: 0.5s;
    margin-bottom: 1rem;
}

.report_page__back:hover{
    color: var(--primary-clr);
    cursor: pointer;
}
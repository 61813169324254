.demo_popup {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.demo_popup__modal{
    padding: 32px 16px;
    height: 95vh;
    position: fixed;
    background-color: white;
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    font-family: 'roboto';
    padding: 32px 64px;
    top: -100%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2%;
    animation: slideIn 0.5s forwards, fadeInOut 5s infinite alternate;
 
}

.demo_popup__modal h4{
    color: var(--primary-clr);
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
}


.pass {
    color: green;
    background-color: #d0f0c0;
    padding: 5px 10px;
    border-radius: 5px;
}
.fail {
    color: red;
    background-color: #ffd9d9;
    padding: 5px 10px;
    border-radius: 5px;
}

.demo_modal__buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 80%;
}

.demo_modal__content h5{
    color: var(--secondary-clr);
    font-weight: 900;
    font-size: 1.5rem;
    margin-bottom: 16px;
    letter-spacing: 2px;
}

.demo_modal__content{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    font-size: 0.9rem;
    width: 600px;
}

.demo_modal__content p{
    text-align: center;
    font-size: 1.2em;
}

.demo_popup__modal .image_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.demo_modal__buttons button{
    width:48px;
}

.demo_modal__image{
    width: 400px;
    border-width: 3px;
    border-style: solid;
    border-color: var(--primary-clr);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    margin-bottom: 16px;
}

.top_skip{
    color: var(--secondary-clr);
    font-weight: 700;
    align-self: flex-end;
    margin-right: -24px;
    cursor: pointer;
    
}

.copilot_muni_form{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    z-index: 9999;
}

.copilot_muni_form__container{
    max-height: 90%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}


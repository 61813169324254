.image_modal .overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.image_modal__content{
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.image_modal__content img{
    margin: 16px;
    max-width: 50vw;
}
.lan_agent{
    font-family: "poppins", sans-serif;
    overflow-x: hidden;
}

.lan_agent .land_agent__vision{
    padding: 3rem 4rem;
    background-color: var(--primary-lighter-clr);
    min-height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--text-clr)
}

.lan_agent .land_agent__secion_title{
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-clr);
    margin-bottom: 2.5rem;
}

.lan_agent .land_agent__vision_points{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lan_agent .land_agent__vision_item{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.lan_agent .land_agent__vision_item span{
    color: var(--primary-clr);
    font-weight: 500;
}

.lan_agent .land_agent_vision_item_icon{
    color: var(--primary-clr);
}

.lan_agent .land_agent__features{
    padding: 3rem 4rem;
}

.lan_agent .land_agent__features_content{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.lan_agent .land_agent__features_image{
    max-width: 45%;
}

.lan_agent .land_agent__features_points{
    min-width: 50%;
}

.lan_agent .land_agent__features_item{
    margin-bottom: 1rem;
}

.lan_agent .land_agent__features_item span{
    font-weight: 500;
    color: var(--primary-clr);
}

.lan_agent .lan_agent__enhance{
    display: flex;
    align-items: center;
    padding: 3rem 4rem 0 4rem;
    background-color: var(--light-gray-clr);

}

.lan_agent .lan_agent__enhance_items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lan_agent .lan_agent__enhance_item span{
    color: var(--primary-clr);
    font-weight: 500;
}

.lan_agent .lan_agent__enhance_img{
    height: 80%;
    object-fit: cover;
    align-self: self-end;
}

.lan_agent .lan_agent__free_trial{
    background-color: var(--primary-clr);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 4rem;
}

.lan_agent .lan_agent__free_trial_top{
    margin-bottom: 1.5rem;
}

.lan_agent .lan_agent__free_trial_items{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lan_agent .lan_agent__free_trial_item{
    font-weight: 300;
}

.lan_agent .lan_agent__free_trial_item span{
    font-weight: 500;
}


/* Small devices (tablets) */
@media (max-width: 767.98px) {

    .lan_agent .land_agent__vision{
        padding: 2rem;
    }


    .lan_agent .land_agent__features_content{
        flex-direction: column;
    }

    .lan_agent .land_agent__features_image{
        max-width: 100%;
    }
    
    .lan_agent .land_agent__features_points{
        min-width: auto;
    }

    .lan_agent__enhance_img{
        display: none;
    }

    .lan_agent .lan_agent__enhance{
        padding: 2rem;
    }

    .lan_agent .land_agent__features{
        padding: 2rem;
    }

    .lan_agent .lan_agent__free_trial{
        padding: 2rem;
    }
    

}
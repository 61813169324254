.municipality_demo{
    background-color: #eaebf1;
    padding: 16px 32px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.municipality_demo__corner_logo{
    position: fixed;
    left: 4%;
    top: 4%;
    max-width: 10%;
    z-index: 999;
    transition: 0.5s;
}

.municipality_demo__corner_logo:hover{
    transform: scale(1.2);
}


.municipality_demo__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.municipality_demo__muni_logo_area{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(2% + 8px);
}
.municipality_demo__lets_get{
    margin-bottom: calc(1.5% + 4px);
}

.municipality_demo__cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: calc(1% + 4px);
    max-width: 90%;
    margin-bottom: calc(1% + 8px);
}

.municipality_demo__cards > div{
    flex: 1;
}

.municipality_demo__run_btn{
    width: 30%;
    min-width: 300px;
    margin-bottom: calc(8px + 1%);
}

.municipality_demo__chat_box{
    width: 100%;
    height: 48px;
}


@media (max-width:768px) {
    .municipality_demo{
        height: auto;
    }
}
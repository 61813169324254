.metric_card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.5rem;
    padding: 1.12rem;
    min-height: 6rem;
    min-width: 12rem;
    flex-shrink: 0;
    flex-grow: 1;
    flex: 1;
    border: 0.05rem solid rgba(18, 99, 66, 0.5);
}

.metric_card:hover{
    cursor: pointer;
}


.metric_card__icon {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    flex-shrink: 0;
}

.metric_card__text_area {
    display: flex;
    gap: 0.8rem;
    align-items: center;
}

.metric_card__text_area h5 {
    margin-bottom: 0;
    font-size: 0.675rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}

.metric_card__amount {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    align-self: center;
}

.metric_card__increase {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.metric_card__increase_per {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.metric_card__increase_per .percentage_increase {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.metric_card__increase_amount {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.metric_card_cc__pen{
    width: 1rem !important;

}

.metric_card_cc__pen:hover{
    color: var(--primary-clr);
    cursor: pointer;
}

.metric_card_acc__edit_text_area{
    display: flex;
    align-items: center;
}

.metric_card_acc__edit_text_area .ico_btn{
    width: 0.8rem;
    height: 0.8rem;
}

.metric_card_acc__edit_text_area input{
    max-width: 6ch;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    border-style: solid;
    border-width: 0 0 1px 0;
    outline: none;
    display: flex;
    align-items: center;
}
.verification_popup{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.verification_popup__container{
    background-color: white;
    padding: 16px 32px;
    width: 40%;
    min-width: 300px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
    text-align: center;
}

.verification_popup__container input{
    min-height: 48px;
    width: 100%;
    padding:4px 8px;
    text-align: center;
}

.verification_popup__container .resend{
    color: var(--primary-clr);
    text-decoration: underline;

}

.verification_popup__container .resend:hover{
    cursor: pointer;
}

.verification_popup__container .error{
    color: red;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
}
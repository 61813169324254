.footer-section {
    background-color: white;
}

.footer__bottom {
    background-color: var(--primary-clr);
    color: var(--text-light-clr);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5em 1rem;
}

.footer__social_links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    gap: 2em;
    margin-bottom: 0;
}

.footer__social_links a{
    color: white;
    transition: 0.5s;
}

.footer__social_links a:hover{
    color: var(--secondary-clr);
}

.footer__social_links li{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.footer__top {
    display: flex;
    gap: 4rem;
    padding: 4em 2rem 2em 2rem;
}

.footer__link_list{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
}

.footer__links {
    list-style-type: none;
    padding: 0;
}

.footer__links .title {
    color: var(--primary-clr);
    font-weight: bold;
}

.footer__links li {
    margin-bottom: 1em;
    
}




/* Small devices (tablets) */
@media (max-width: 767.98px) {
    

    .footer__bottom{

        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .footer__top{
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer__social_links li{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    
    
}

/* Medium devices (desktops)
@media (min-width: 768px) and (max-width: 991.98px) {
    

    
}

Large devices (desktops)
@media (min-width: 992px) and (max-width: 1199.98px) {} */
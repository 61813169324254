.modal2 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 65, 8, 0.5);
  z-index: 9999;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.popup-new {
  min-height: 400px;
  position: fixed;
  font-size: 16px;
  background-color: white;
  width: 45em;
  height: 60vh;
  border-radius: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px 80px;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slideIn 0.5s forwards, fadeInOut 5s infinite alternate;
  
}

@keyframes slideIn {
  from {
    top: -100%; /* Start off the screen */
  }
  to {
    top: 50%; /* Move to the center of the screen */
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1; /* Start with full opacity */
  }
  50% {
    opacity: 0.9; /* Midway, make it slightly transparent */
  }
  100% {
    opacity: 1; /* Return to full opacity */
  }
}



.popup-new .left-area {
  min-width: 50%;
  align-items: start;
}

.popup-new .left-area .logo{
  margin-bottom: 2em;
  width: 40%;
}

.popup-new .left-area h1 {
  color: #29781D;
  width: 100%;
  font-size: 32pt;
  font-weight: 900;
  text-align: left;
}

.popup-new .left-area p {
  color: #707070;
  font-size: 10pt;
  text-align: justify;
}

.popup-new .left-area button{
  width: 50%;
  height: 40px;
  background: #29781D;
  border-style: none;
  color: white;
  border-radius: 2%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  text-transform: uppercase;
  font-size: 1rem;
}

.popup-new .left-area button:hover{
  background:#18550d;
}

.popup-new .right-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 65%;
  height: 100%;
}

.popup-new .right-area form {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.3);
  width: 70%;
  height: 90%;
  border-radius: 2%;
  padding: 16px;
  align-items: center;
  justify-content: center;
}

.popup-new .right-area form input {
  width: 100%;
  height: 40px;
  color: #5F5F5F;
  border-style: none;
  border-radius: 2%;
  background: #e2e2e2;
  padding-left: 8px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.popup-new .right-area form input:focus {
  outline: none;
  border: 1px solid #29781D;
}

.popup-new .right-area form button {
  width: 100%;
  height: 40px;
  background-color: #29781D;
  border-style: none;
  color: white;
  border-radius: 2%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  text-transform: uppercase;
}

.popup-new .right-area form button:hover {
  background-color: #18550d;
}

.popup-new .close {
  position: absolute;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  top: 0px;
  right: 0px;
  margin-right: 16px;
  margin-top: 4px;
}

.popup-new .close:hover {
  color: #29781D;
}

.ty-image img {
  margin-top: 2vh;
  margin-bottom: 2vh;
  height: 100px;
}

@media only screen and (max-width: 780px) {
  .popup-new {
    width: 40em;
  }

}

@media only screen and (max-width: 640px) {

  .popup-new {
    width: 85%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2em;

  }

  

  .popup-new .left-area {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

 

  .popup-new .left-area h1 {
    margin-top: 0.2em;
    font-size: 1rem;
    text-align: center;
  }

  .popup-new .left-area p {
    text-align: center;
    font-size: 12px;
  }

  .popup-new .right-area {
    width: 100%;
  }

  .popup-new .right-area form {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);

  }

  .popup-new .right-area form input {
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    font-size: 10pt;

  }

  .popup-new .right-area form button {
    font-size: 10pt;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .popup-new .close {
    font-size: 24px;
  }

  .ty-image img {
    height: 80px;
  }

  .popup-new .left-area .logo{
    width: 40%;
  }


}


.contactus__container {
    font-family: "poppins", sans-serif;
    display: flex;
    background-color: var(--primary-clr);
}

.contactus_left-area {
    flex: 1;
    display: flex;
}

.contactus_left-area img{
    object-fit: cover;
}

.contactus_right-area h1 {
    color: var(--secondary-clr);
    font-size: 2rem;
    margin-bottom: 20px;
}

.contactus_right-area p {
    font-size: 1.1rem;
    line-height: 1.5;
}

.contactus_right-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 3rem;
    margin: 3rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.contactus_right-area form {
    display: flex;
    flex-direction: column;
}

.contactus_right-area input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contactus_right-area textarea{
    
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contactus_right-area button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: var(--secondary-clr);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contactus_right-area button:hover {
    background-color: var(--secondary-dark-clr);
}

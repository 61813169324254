.setting_sidebar {
    width: 21.rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 1rem;
    background-color: white;
    border-radius: 0.4375rem;
}

.setting_sidebar li {
    margin-bottom: 0;
    width: 13rem;
    height: 2.5rem;
    transition: 0.5s;
    padding: 0.38rem 0.62rem;
    display: flex;
    align-items: center;
    color: #3E3E3E;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 0.25rem;
}

.setting_sidebar li:hover {
    background-color: var(--secondary-clr);
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .setting_sidebar{
        display: none;
    }
}
.municipality_demo_tab{
    background-color: #eaebf1;
    padding: 24px 32px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}

.municipality_demo_tab__corner_logo{
    position: fixed;
    left: 4%;
    top: 4%;
    max-width: 10%;
    z-index: 999;
    transition: 0.5s;
}

.municipality_demo_tab__corner_logo:hover{
    transform: scale(1.2);
}

.municipality_demo_tab__corner_logo img:hover{
    cursor: pointer;
}

.municipality_demo_tab__muni_logo_area .atlanta_logo:hover{
    cursor: pointer;
}

.municipality_demo_tab__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.municipality_demo_tab__muni_logo_area{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(2% + 8px);
    margin-bottom: calc(8px + 0.5%);
}

.municipality_demo_tab__address > h6{
    margin-bottom: calc(4px + 0.5%);
    text-align: center;
}

/* container for two lines of tabs */
.municipality_demo_tab__tabs_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

/* style setting for a line of tabs */
.municipality_demo_tab__tabs{
    display: flex;
    gap: calc(4px + 0.5%);
    flex-wrap: wrap;
    justify-content: center;
}


/* style for one tab */
.municipality_demo_tab__tabs > div{
    width: 15%;
    min-width: 200px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

/* .municipality_demo_tab__chat_box{
    width: 100%;
    height: 48px;
} */

.municipality_demo_tab__table_area{
    width: 100%;
    display: flex;
    justify-content: center;
}

.municipality_demo_tab__table_area table{
    width: 93%;
}

.municipality_demo_tab__table_area tbody{
    height: 50px !important;
    overflow-y: scroll !important;
}

.municipality_demo_tab__table_area th{
    
    padding-left: calc(8px + 1%);
}

.municipality_demo_tab__table_area td{
    padding-left: calc(8px + 1%);
    max-width: 200px !important;
}

.municipality_demo_tab__buttons{
    position: fixed;
    bottom: calc(4px + 1%);
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.municipality_demo_tab__buttons button{
    width: 10%;
    min-width: 160px;
}

.municipality_demo_tab .genius_btn{
    position: fixed;
    top: 2%;
    right:3%;
    z-index: 999;
}

.municipality_demo_tab .genius_btn img{
    width: 50px
}

.municipality_demo_tab .genius_btn img:hover{
    cursor: pointer;
}


.hide_in_small{
    display: block;
}

.hide_in_big{
    display: none;
}

.bg-body-tertiary{
    background-color: rgba(0, 0, 0, 0) !important;
}

@media(max-width: 768px) {
    .municipality_demo_tab{
        height: auto;
    }

    .hide_in_small{
        display: none;
    }

    .hide_in_big{
        display: block;
    }

    .genius_btn{
        margin-top: 10%;
    }
}
.iq_tab{
    border-radius: 10px 10px 0 0;
    width: 100%;
    min-width: 240px;
    min-height: 48px;
    max-height: 64px;
    font-weight: 600;
    outline: none;
    border: 0 solid rgba(0, 0, 0, 0);
    background-color: white;
    box-shadow: 1px -2px 3px var(--box-shadow-clr);
    transition: 0.5s;
}

.iq_tab:hover, .iq_tab__active{
    background-color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
}

.iq_tab__disable{
    opacity: 0.4;
}

.iq_tab__disable:hover{
    opacity: 0.4;
    background-color: white;
    border: 0 solid rgba(0, 0, 0, 0);
    cursor: default;

}


.iq_button{
    background-color: var(--primary-clr);
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 2px;
    color: white;
    font-weight: 700;
    transition: 0.5s;
}

.iq_button:hover{
    background-color: var(--primary-dark-clr);
}

.iq_button__deactivate{
    background-color: rgb(82, 82, 82);
    color: rgb(151, 151, 151);
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 2px;
    font-weight: 700;
    transition: 0.5s;
}

.iq_button__deactivate:hover{
    cursor: default;
}
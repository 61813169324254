.iq_information{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(59, 59, 59, 0.527);
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}

.iq_information__container{
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 1em;
    border-radius: 10px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
}

/* .iq_information__container img{
    width: 100px;
    margin-bottom: 16px;

} */

.iq_information__container h5{
    font-size: 18px;
}

.iq_information__container button{
    width: 200px;
}
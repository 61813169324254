.new_login_page {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #F9F9F9;
    padding: 1.56rem;
}

.new_login__image_side {
    flex: 1;
}


.new_login__image_side img {
    box-sizing: border-box;
    border-radius: 1.125rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.new_login__login_form_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
}

.new_login__login_form_area .logo{
    min-width: 10rem;
}

.new_login__login_form {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    gap: 1.5rem;
    padding: 1.62rem;
    min-height: 80%;
    min-width: 20rem;
}


.new_login__form_forgot_text {
    align-self: stretch;
    color: #3A856E;
    text-align: right;
    font-size: 0.875rem;
    font-weight: 400;
}

.new_login__form_forgot_text:hover {
    cursor: pointer;
    color: var(--primary-clr);
}

.new_login__form_label {
    color: var(--dark, #3E3E3E);
    font-size: 1rem;
    font-weight: 400;
    /* 1.48rem */
}

.new_login__form_title_area {
    display: flex;
    align-items: center;
    width: 100%;
}

.new_login__form_title {
    color: #171721 !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    align-self: flex-start !important;
}

.new_login__button_area {
    width: 100%;
}

.new_login__have_account_text {
    color: #707070;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
}

.new_login__have_account_text span {
    color: var(--primary-clr);

}

.new_login__have_account_text span:hover {
    cursor: pointer;
    color: var(--primary-dark-clr);
}

.new_login__or_area {
    display: flex;
    justify-content: space-between;
    color: #8083A3;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
}

.new_login__or_area img {
    width: 10rem;
}

.social_media_button {
    border-radius: 0.3125rem;
    border: 1px solid #E5E0EB;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: 2.5rem;
    width: 100%;
    transition: 0.5s;
    color: #8083A3;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
}

.social_media_button:hover {
    background-color: var(--primary-clr);
    color: white;
    cursor: pointer;
}

.new_login__form_info {
    color: #626262;
    font-size: 0.875rem;
    font-weight: 500;
    /* 164.286% */
}

.new_login__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;
}

.new_login__form_input_text {
    position: relative;
}

.new_login__form_input_text .email_icon {
    position: absolute;
    right: 5%;
    top: 55%;
}

.new_login__form_input_text .password_eye_icon {
    position: absolute;
    right: 5%;
    top: 36%;

}

.new_login__form_input_text .password_eye_icon:hover {
    cursor: pointer;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-clr) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-clr) !important;
}

.MuiInputLabel-root.Mui-focused {
    color: var(--primary-clr) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 1rem 1rem !important;
    height: 1rem !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

    padding: 1rem 1rem !important;
    height: 1rem !important;
}

.new_login__cancel {
    color: var(--primary-clr);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.new_login__cancel:hover {
    color: var(--primary-dark-clr);
    cursor: pointer;
}

.new_login__resend {
    color: #626262;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
}

.new_login__resend span {
    color: var(--primary-clr);
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration-line: underline;
}

.new_login__resend span:hover{
    color:var(--primary-dark-clr);
    cursor: pointer;
}
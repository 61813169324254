/* Form container */
.muni_form {
    background-color: white;
    border-radius: 2px;
    max-width: 800px;
    min-width: 400px;
    padding: 32px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: space-between;
    z-index: 9999;
}

/* header title */
.muni_form__header h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: clamp(2rem 1.5rem 1rem);
    color: var(--primary-clr);
}

.muni_form__header>p {
    font-size: 0.8rem;
    margin-bottom: 0.5em;
}

/* Content area need to scroll */
.muni_form__content_area {
    padding: 0 8px 1em 0;
    max-height: 50vh;
    margin-bottom: 1em;
}

/* Form  */
.muni_form form {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 50vh;
}

/* this is for line of input */
.muni_form__form_line {
    margin-bottom: 1em;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

/* this is input with placeholder */
.muni_form__input {
    position: relative;
    min-width: 45%;
    flex-grow: 1;
}

/* this is input */
.muni_form__input input {
    width: 100%;
    height: 3em;
    padding: 10px 16px 4px 16px;
    outline: none;
    border: none 0;
    background-color: #f5f5f8;
}

/* input text focus */
.muni_form__input input:focus {
    border-color: var(--primary-clr);
    border: solid 1px var(--primary-clr)
}

.muni_form__input_radio {
    flex-direction: column;
}

/* Place holder */
.muni_form__input .place_holder {
    position: absolute;
    padding: 0 4px;
    left: 8px;
    top: 25%;
    color: gray;
    font-size: 0.9rem;
    transition: 0.3s;
    pointer-events: none;
    display: flex;
    align-items: center;
}

/* Label for high with */
.muni_form__input .label {
    text-align: justify;
}

/* Place holder postion after click input or which is has value */
.muni_form__input input:focus+.place_holder,
.muni_form__input input:not([value=""])+.place_holder {
    top: 0;
    font-size: 0.5rem;
}


/* button area */
.muni_form__button_area{
    display: flex;
    justify-content: space-between;
    gap: 2em;
}

/* Page 2 */
.muni_form__page2{
    text-align: justify;
    font-size: 1.2vw;
    
}


/* Agreement Page */
.muni_form__page3{
    text-align: justify;
    overflow-y: scroll;
    max-height: 50vh;
    padding-right: 8px;
}


.muni_form__page3 ol li{
    margin-bottom: 0.5em;
}

@media (max-width:576px) {
    .muni_form{
        transform: scale(0.8);
    }
}


@media(max-width: 767.98px){
    .muni_form__page2{
        font-size: 0.7rem;
    }
}

.iq_loading{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(59, 59, 59, 0.95);
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.iq_loading__container{
    position: absolute;

    width: 400px;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iq_loading__container img{
    width: 100px;
    margin-bottom: 16px;

}

.iq_loading__container h5{
    font-size: 18px;
    font-weight: bold;
}
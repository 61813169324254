.user_home {
    font-family: "poppins", sans-serif;
    overflow-x: hidden;
}

.user_home__hero {
    background-color: var(--primary-clr);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.user_home__title {
    color: #FFF;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
}

.user_home__cards {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.user_home__card {
    border-radius: 0.75rem;
    background: rgba(255, 255, 255, 0.10);
    padding: 1.25rem;
    flex: 1;
    min-width: 20rem;
    color: white;
    display: flex;
    gap: 1rem;
}

.user_home__card_icon {
    background-color: var(--primary-clr);
    border-radius: 50%;
    padding: 4px;
}

.user_home__card_title {
    font-size: 1.125rem;
    font-weight: 600;
}

.user_home__card_para {
    color: rgba(255, 255, 255, 0.80);
    font-size: 0.875rem;
    font-weight: 400;
}

.user_home__card_button {
    display: flex;
    height: 2.25rem;
    max-width: 18ch;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.3125rem;
    border: 1px solid #FFF;
    text-wrap: nowrap;
    transition: 0.5s;
}

.user_home__card_button:hover {
    background-color: var(--primary-clr);
    cursor: pointer;
    color: white;
}

.user_home__features {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;;

}

.user_home__features_title {
    color: #3E3E3E;
    text-align: center;
    font-size: 2.375rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.user_home__recent {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.user_home__recent_title {
    color: #3E3E3E;
    text-align: center;
    font-size: 2.375rem;
    font-weight: 600;
}

.user_home__recent_plans{
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
    scrollbar-width: thin;
    padding: 2rem;
}
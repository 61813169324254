.municipality_demo_table td img:hover{
    cursor: pointer;
}

.info_column_q{
    position: relative;
}

.info_column_q .q_mark{
    position: absolute;
    font-size: 0.7em;
    background-color: var(--secondary-clr);
    color: var(--text-clr);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 4px;
    top: 4px;
}

.q_mark:hover::after,
.q_mark:hover::before{
    z-index: 9999;
    position: absolute;
    content: attr(data-tooltip);
    background-color: var(--secondary-clr);
    min-width: 200px;
    text-align: center;
    border-radius: 10px;
    padding: 8px 4px;
    transform: translateX(-50%) translateY(50%);
}

.municipality_demo_table th{
    background-color: rgb(190, 190, 190);
}

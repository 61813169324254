.appraisar__features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 4rem;
    align-items: center;
    justify-content: center;
}

.appraisar__section_img{
    max-width: 20rem;
}

.appraisar__secion_title {
    text-align: center;
    font-weight: 900;
    font-size: 2.5rem;
    color: var(--secondary-clr);
}

.appraisar__section_hr {
    height: 4px;
    background-color: var(--primary-clr);
    opacity: 1;
    width: 5ch;

}

.appraisar__features_points {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.appraisar__features_item {
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    align-self: stretch;
    font-weight: 300;
}

.appraisar__features_icon {
    padding: 0.5rem;
    width: 2.5rem !important;
    height: 2.5rem !important;
    background-color: var(--primary-clr);
    border-radius: 50%;
    color: var(--secondary-clr);
}

.appraisar__features_item_text {
    color: rgb(94, 94, 94);
}

.appraisar__features_item_text_title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

@media (width:768px) {
    .appraisar__features {
        padding: 1rem;
    }

    .appraisar__secion_title {
        font-size: 1.5rem;
    }

    .appraisar__features_item {
        max-width: 100%;
    }
}
.prof_info {
    height: 100vh;
}

.prof_info h5 {
    color: #3E3E3E;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.prof_info button {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    color: var(--Gray-White, #FFF);
    font-size: 1rem;
    font-weight: 500;
    transition: 0.5s;
}

.prof_info button:hover {
    background-color: var(--primary-dark-clr);
}

.prof_info__container {
    background-color: #F9F9F9;
    display: flex;
    gap: 1.5rem;
    padding: 1.87rem 6.25rem;
    min-height: calc(100% - 4.125rem);
}

.prof_info__main {
    flex-grow: 1;
    overflow-y: auto;
}

.prof_info__part {
    background-color: white;
    padding: 2rem 2rem 2.38rem 2rem;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
}

.prof_info__profile {
    width: 6.125rem;
    height: 6.125rem;
    position: relative;
    margin-bottom: 1.87rem;
}

.prof_info__profile .profile{
    border: 1px solid var(--primary-clr);
    padding: 4px;
    border-radius: 50%;
}

.prof_info__profile .camera{
    position: absolute;
    right: 0;
    bottom: 0;
}

.prof_info__profile .camera:hover{
    cursor: pointer;
}

.prof_info__inputs{
    margin-bottom: 2rem;
}

.prof_info__inputs_sets{
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.text_area_style{
    width: 100%;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    padding: 16.5px 14px;
    border-color: rgba(118, 118, 118, 0.8);
    border-width: 1px;
}

.text_area_style:hover{
    border-color: var(--primary-clr);
    
}

.text_area_style:focus{
    border-color: var(--primary-clr);
    outline: none;
    border-width: 2px;
}

@media only screen and (max-width: 992px) {
    

    .prof_info__container {
        padding: 1rem 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .prof_info__inputs_sets {
        flex-direction: column;
    }

    .prof_info__container{
        padding: 1rem;
    }
}
.lan_copilot_hero{

    background-color: var(--primary-clr);
    display: flex;
    align-items: center;
    padding: 3rem 0 3rem 4rem;
}

.lan_copilot_hero__left{
    min-width: 50%;
    flex: 1;
    flex-shrink: 0;
    flex-grow: 1;
}


.lan_copilot_featurs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3rem 4rem;
}

.lan_copilot_featurs_data{
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.lan_copilot_features_items{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1;
}
.lan_copilot_item {
    display: flex;
    min-width: 20rem;
    padding: 1.25rem 1.75rem 1.25rem 1.5rem;
    align-items: center;
    align-self: stretch;
    border-radius: 0.75rem;
    border-left: 4px solid var(--primary, #126342);
    background: rgba(18, 99, 66, 0.05);
    gap: 0.5rem;
}

.lan_copilot_featurs_img{
    flex: 1;
}

.lan_copilot_key_features{
    display: flex;
    align-items: center;
    gap: 4rem;
    background: #F2FFFA;
    padding: 3rem 4rem;
}

.lan_copilot_key_features_right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.lan_copilot_exp{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--primary-clr);
    gap: 3rem;
}

.lan_copilot_exp_texts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding: 2rem 3rem;
}

.sun_copilot_container{
    position: relative;
}

.sun_copilot{
    position: absolute;
    left: 45%;
    top: 30%;
    transform: translate(-50%, -50%);
}


@media (max-width: 767.98px) {

    .lan_copilot_hero{
        flex-direction: column;
        padding: 2rem;
    }

    .lan_copilot_featurs{
        padding: 2rem;
    }

    .lan_copilot_key_features{
        padding: 2rem;
        flex-direction: column;
    }
}
.setting_other_nav{
    width: 260px;
    height: 100vh;
    flex-shrink: 0;
    background-color: white;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    border-style: solid;
    border-color: #82828229;
    border-width: 0 1px 0 0;
    padding: 16px 12px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: transform 0.5s ease;
    transform: translateX(-100%);
}


@media screen and (max-width: 768px) {

    .setting_other_nav__mobile_active{
        display: block;
        transform: translateX(0);
    }
}
.checkout_page {
  font-family: "poppins", sans-serif;
  padding: 1rem 3rem;
  background: #F9F9F9;
}


.checkout_page__top {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.checkout_page__price {
  color: var(--primary-clr);
  font-size: 2.5rem;
  font-weight: 600;
}

.checkout_page__price span {
  font-size: 1.125rem;
}

.checkout_page__plan {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  flex: 3;
}

.checkout_page__plan_title {
  color: #626262;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: capitalize;
}

.checkout_page__contents {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.5rem 2rem;
  align-items: flex-start;
}

.checkout_page__contents_item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.checkout_page__summary {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.checkout_page__summary_title {
  color: #3E3E3E;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1.38rem;
}

.checkout_page__summary_items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkout_page__summary_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout_page__summary_item_text {
  color: #9C9C9C;
  font-size: 0.875rem;
  font-weight: 400;
}

.checkout_page__summary_item_price {
  color: #3E3E3E;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkout_page__summary_bottom {
  margin-top: 3rem;
}

.checkout_page__summary_total {
  color: #252121;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkout_page__summary_total_price {
  color: #252121;
  font-size: 0.875rem;
  font-weight: 500;
}

.checkout_page__summary_terms_text {
  color: #3E3E3E;
  font-size: 0.875rem;
  font-weight: 400;
}

.checkout_page__summary_terms_area {
  display: flex;
  gap: 4px;
  align-items: center;
}

.checkout_page__stripe_area {
  max-width: 42rem;
  margin-top: 2rem;
}

.checkout_page__stripe_title {
  color: #3E3E3E;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.checkout_page__stripe_card{
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.checkout_page__note{
  font-size: 0.65rem;
  color: #3E3E3E;
  margin-top: 0.5rem;
  max-width: 42rem;
}

.terms_popup{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.terms_popup_content{
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 80%;
  max-height: 90%;
  padding: 0 0 2rem 0;
  background-color: white;
}

.terms_popup_content .terms_title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 2rem 4rem;
  background-color: var(--primary-clr);
  color: var(--secondary-clr);
}

.term_popup_texts{
  background-color: white;
  overflow-y: auto;
}

.term_popup_buttons{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-self: flex-end;
  padding-right: 2rem;
  padding-top: 1rem;
}

.terms_popup_close{
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  color: var(--secondary-clr);
  transition: 0.5s;
}

.terms_popup_close:hover{
  color: var(--secondary-dark-clr);
}


@media (max-width:768px) {
  .terms_popup_content{
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 95%;
    max-height: 95%;
    padding: 0 0 2rem 0;
    background-color: white;
  }

  .terms_popup_content .terms_title {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }
}
.event_even_subs__top_banner {
  display: flex;
  justify-content: space-between;
  background-color: var(--secondary-clr);
  align-items: center;
  padding: 1rem 6rem;
}

.event_plans,
.event_comparison {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  max-width: 1068px;
}

.event_plans__heading,
.event_comparison__heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-top: 2rem;
}

.event_toggle {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto;
}

.event_toggle__label {
  line-height: 22px;
  font-size: 1.5rem;
}

.event_plans__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.event_plan {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 18px;
  padding: 20px;
}

.event_plan__content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dedede;
}

.event_plan__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.event_plan__info__name {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
  color: #3e3e3e;
}

.event_plan__info__description {
  font-size: 16px;
  line-height: 24px;
  color: #9c9c9c;
}

.event_plan__features {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.event_plan__feature {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event_plan__feature__icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(18, 99, 66, 0.1);
}

.event_plan__feature__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #3e3e3e;
}

.event_plan__price {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;
}

.event_plan__price_heading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event_plan__price_heading__amount {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #126342;
}

.event_plan__price_heading__duration {
  font-size: 12px;
  line-height: 24px;
  color: #9c9c9c;
}

.event_plan__price_detail {
  line-height: 23px;
  color: #3e3e3e;
}

.event_plan__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  background-color: #126342;
  border: none;
  text-decoration: none;
  transition: 0.5s;
}

.event_plan__link:hover {
  background-color: var(--primary-dark-clr);
}

.event_table__header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  gap: 10px;
  min-height: 48px;
  background-color: rgba(18, 99, 66, 0.03);
  border-radius: 12px 12px 0 0;
  padding: 10px 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  color: #126342;
}

.event_table__first_col {
  grid-column: span 3;
}

.event_table__row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  gap: 10px;
  min-height: 50px;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  line-height: 22px;
  padding: 10px 20px;
}

.event_table__cell {
  text-align: center;
}

.event_table__row:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: none;
}

.event_comparison_container {
  padding-bottom: 3rem;
}

@media (max-width: 1024px) {
  .event_plans__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .event_even_subs__top_banner {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .event_plans__container {
    grid-template-columns: 1fr;
  }

  .event_table__header,
  .event_table__row {
    grid-template-columns: repeat(5, 1fr);
  }

  .event_table__first_col {
    grid-column: span 2;
  }

  .event_even_subs__top_banner_texts {
    text-align: center;
  }
  .event_even_subs__top_banner {
    flex-direction: column;
  }
}

.appraisal_form{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    z-index: 99;
}

.appraisal_form .appraisal_form_container{
    position: relative;
    background-color: white;
    width: 90%;
    height: 90%;
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.appraisal_form .appraisal_form_container .appraisal_form_close{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: var(--primary-clr);
    cursor: pointer;
    transition: 0.5s;
}

.appraisal_form .appraisal_form_container .appraisal_form_close:hover{
    color: var(--primary-dark-clr);
}

.appraisal_form .appraisal_form_bottom{
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
}

.appraisal_form .appraisal_form_buttons{
    display: flex;
    gap: 1rem;
}

.appraisal_form .appraisal_form_contents{
    display: flex;
    flex-direction: column;
    height: 85%;
}


.appraisal_form .aparaisal_title{
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-clr);
    margin-bottom: 0.5rem;
}

.appraisal_form .apparaisal_sub_title{
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.appraisal_form .apparaisal_main_content{
    padding: 1rem;
    border: 2px groove rgba(0, 0, 0, 0.11);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    scrollbar-width: thin;
    align-self: stretch;
}


.apparaisal_flex{
    display: flex;
    gap: 1rem;
}


.appraisal_form .appraisal_button_p{
    min-width: 8rem;
    padding: 0.5rem;
    background-color: var(--primary-clr);
    border: 1px solid var(--primary-clr);
    cursor: pointer;
    transition: 0.5s;
    color: white;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.appraisal_form .appraisal_button_p:hover{
    background-color: var(--primary-dark-clr);
    border: 1px solid var(--primary-dark-clr);
}

.appraisal_form .appraisal_button_s{
    min-width: 8rem;
    padding: 0.5rem;
    border: 1px solid var(--primary-clr);
    cursor: pointer;
    transition: 0.5s;
    color: var(--primary-clr);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.appraisal_form .appraisal_dots{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.appraisal_form .appraisal_dot{
    color:#ddd;
    font-size: 1rem;
    transition: 0.5s;
    cursor: pointer;
    transition: 0.5s;
}
.appraisal_form .appraisal_dot:hover{
    color: var(--primary-clr);
}
.appraisal_form .ap_current_page{
    color: var(--primary-clr);
    font-size: 1.5rem;
}
.appraisal_form .appraisal_button_s:hover{
    background-color: var(--primary-dark-clr);
    color: white;
}

.appraisal_form .apparaisal_section_title{
    font-weight: 700;
}

.appraisal_form .apparaisal_section{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

@media (max-width:768px){

    .appraisal_form .appraisal_form_container{
        padding: 2rem;
    }
    .apparaisal_flex{
        flex-direction: column;
    }

    .appraisal_form .aparaisal_title{
        font-size: 1.2rem;
    }
}
.new_filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 28.125rem;
    background-color: white;
    height: 100%;
}

.new_filter__title_area {
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1rem 1rem 1rem;
    border-style: solid;
    border-width: 0 0 0.0625rem 0;
    border-color: #DEDEDE;
    align-items: center;
}

.new_filter__title_area h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    color: #3E3E3E;
}

.new_filter__title_area img{
    background-color: white;
    transition: 0.5s;
}

.new_filter__title_area img:hover{
    background-color: var(--primary-clr);
    cursor: pointer;
}

.new_filter__filters {
    display: flex;
    flex-direction: column;
    gap: 1.56rem;
    padding: 2rem 1.22rem;
    overflow-y:hidden;
    transition: 0.5s;
    height: 100%;
}

.new_filter__filters:hover {
    overflow-y: auto;
    scrollbar-width: thin;
}

.new_filter__filters h6 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #2E2C34;
}

.new_filter__filters h5 {

    font-size: 1rem;
    font-weight: 500;
    color: #2E2C34;
    margin-bottom: 0;
}

.new_filter__filters input[type="text"] {
    border-radius: 0.3125rem;
    border: 1px solid #DEDEDE;
    background: #FFF;
    height: 2.5rem;
    width: 100%;
    padding: 0.66rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
    transition: 0.5s;
}


.new_filter__filters input:focus {
    outline: none;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
}

.new_filter__filters select {
    border-radius: 0.3125rem;
    border: 1px solid #DEDEDE;
    background: #FFF;
    height: 2.5rem;
    width: 100%;
    padding: 0.66rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
    transition: 0.5s;
}


.new_filter__filters select:focus {
    outline: none;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
}

.new_filter_buttons_area {
    padding: 1.5rem 1.22rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    height: 5.5rem;
    border-style: solid;
    border-width: 0.0625rem 0 0 0;
    border-color: #DEDEDE;

}

.new_filter_buttons_area .secondary_btn {
    display: flex;
    width: 8.75rem;
    height: 2.5rem;
    padding: 0.6875rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #2E2C34;
    border-radius: 0.3125rem;
    border: 1px solid #EBEAED;
    background: #FFF;
    outline: none;
    transition: 0.5s;
}

.new_filter_buttons_area .primary_btn {
    display: flex;
    width: 8.75rem;
    height: 2.5rem;
    padding: 0.6875rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #FBFAFC;
    border-radius: 0.3125rem;
    background: #126342;
    outline: none;
    border: none;
    transition: 0.5s;
}

.new_filter_buttons_area button:hover {
    background-color: var(--primary-dark-clr);
    color: #FBFAFC;
}

.filter_input_with_button {
    display: flex;
}

.filter_input_with_button .keyword_search_btn {
    border-radius: 0rem 0.3125rem 0.3125rem 0rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.filter_input_with_button .keyword_search_btn:hover {
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}


.new_filter__filters .filter_min_max__inputs {
    display: flex;
    gap: 0.81rem;
    align-items: center;
}

.new_filter__filters .filter_min_max__values {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #84818A;
    font-weight: 500;
    margin-bottom: 0.94rem;
}

.new_filter__filters .filter_min_max__label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
}

.new_filter__filters .filter_with_count_list ul {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    gap: 0.625rem;
    justify-content: space-between;
    align-items: center;
}

.new_filter__filters .filter_with_count_list li {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    border: 1px solid #DEDEDE;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    color: #84818A;
    transition: 0.5s;
}

.new_filter__filters .filter_with_count_list li:hover {
    cursor: pointer;
    background-color: #E7EFEC;
}


.new_filter__filters .filter_with_accordion .acc_title_area {
    display: flex;
    align-items: center;
    gap: 0.62rem;
}

.new_filter__filters .filter_with_accordion .acc_data_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new_filter__filters .filter_with_accordion .text_with_count {
    display: flex;
    gap: 0.44rem;
}


.new_filter__filters .filter_with_accordion .title {
    font-size: 0.875rem;
    font-weight: 400;
    color: #3E3E3E;

}

.new_filter__filters .filter_with_accordion .count {
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
}

.new_filter__filters .filter_with_accordion input[type="checkbox"] {
    width: 0.9375rem;
    height: 0.9375rem;
}



@media only screen and (max-width: 767px) {
    .new_filter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 22rem;
        background-color: white;
        height: 100%;
    }
}
.plan_new {
    width: 18rem;
    height: 20rem;
    border-radius: 0.625rem;
    border: 1px solid #DFDFDF;
    background: #FFF;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.plan_new:hover{
    border: 1px solid var(--primary-clr);
}

.plan_new__description_area {
    padding: 1rem 0.81rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan_new__image img{
    border-radius: 0.625rem 0.625rem 0 0; 
    width: 100%;
    height: 10rem;
}

.plan_new__title_area {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
}

.plan_new_container{
    width: 100%;
    height: 100%;
    background: #FFF;
    flex-shrink: 0;
    border-radius: 0.625rem;
    flex-grow: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
}

.plan_new_container:hover{
    cursor: pointer;
}


.plan_new .plan_heart{
    position: absolute;
    bottom: 35%;
    right: 7.4%;
    color: var(--primary-clr);
}

.plan_new .plan_cart{
    position: absolute;
    bottom: 23%;
    right: 5.6%;
}

.plan_new .plan_heart:hover{
    cursor:grabbing;
}

.plan_new__title_area h6 {
    color: #000;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem;
    text-transform: capitalize;
    margin-bottom: 0;
}

.plan_new__features {
    display: flex;
    border-style: solid;
    border-color: #EBEBEB;
    border-width: 0.0625rem 0 0 0;
    margin-top: 0.94rem;
    padding-top: 0.5rem;
    justify-content: space-between;
}

.plan_new__features .icon_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 4rem;
}

.plan_new__features .f_count {
    color:  #9C9C9C;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
}


@media only screen and (max-width: 767px) {
    .plan_new {
        height: 22.375rem;
        border-radius: 0.625rem;
        border: 1px solid #DFDFDF;
        background: #FFF;
        flex-shrink: 0;
        flex-grow: 0;
        transition: 0.5s;
    }
}
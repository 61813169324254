.genius_popup{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.genius_popup__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 8px;
}

.genius_popup__container input{
    width: 50%;
    min-height: 42px;
    border-radius: 5px;
    border-style: none;
    padding-left: 16px;
    min-width: 260px;
}

.genius_popup__container button{
    width: 10%;
    min-width: 160px;
    height: 42px;
}
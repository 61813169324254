.iq_close_button{
    top: 8px;
    right: 8px;
    background-color: var(--secondary-clr);
    border-style: none;
    border-radius: 5px;
    padding: 2px 8px;
    font-weight: 900;
    color: var(--text-clr);
    position: absolute;
    z-index: 1;
    
}

.iq_close_button:hover{
    background-color: var(--secondary-dark-clr);
    color: var(--text-light-clr);
}


@media (max-width: 768px) {
  
    .iq_close_button{
        padding: 0.1em 0.6em;
        font-size: 0.8rem;
    }
  }
/* Using overlay styling to cover the branding */
.calendly-inline-widget iframe::-webkit-scrollbar {
    display: none;
    /* Hide scrollbars to clean up UI */
}

.plug_and_play {
    padding: 1rem 3rem;
    background-color: ghostwhite;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "poppins", sans-serif;
    min-height: calc(100vh - 64px);
}

.plug_and_play_container {
    padding: 3rem 3rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    height: auto;
}

.plug_and_play_main {
    display: flex;
    gap: 2.5rem;
    height: 100%;

}

.plug_and_play_left,
.plug_and_play_right {
    align-self: stretch;
}

.plug_and_play_left {
    width: 60%;
}

.plug_and_play_right {
    display: flex;
    flex-direction: column;
}

.plug_and_play_title {
    font-size: 2rem;
    color: var(--primary-clr);
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.plug_and_play_sub_title {
    font-weight: 300;
    margin-bottom: 1rem;
    columns: var(--primary-clr);
}

.plug_and_play_submit {
    background-color: var(--primary-clr);
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    align-self: flex-end;
    transition: 0.5s;
    margin-top: 1rem;
}

.plug_and_play_submit:hover {
    background-color: var(--primary-dark-clr);
}

.pp_thank_you_container {
    font-family: "poppins", sans-serif;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
    background-color: var(--primary-clr);
    padding: 0 1rem;
}

.pp_thank_you_title {
    color: var(--secondary-clr);
    font-weight: 700;
    font-size: 2.5rem;
}

.pp_thank_you_para {
    color: white;
    font-size: 1.2rem;
}

.pp_thank_you_button {
    padding: 1rem 1.5rem;
    background-color: var(--secondary-clr);
    font-weight: 600;
    color: var(--primary-clr);
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.5s;
}

.pp_thank_you_button:hover {
    background-color: var(--secondary-dark-clr);
}

.pp_thank_you_emoji {
    font-size: 10rem;
    margin-bottom: 2rem;
}


/* Small devices (tablets) */
@media (max-width: 767.98px) {
    .plug_and_play{
        padding: 1rem;
    }
    .plug_and_play_container{
        padding: 1.5rem;
    }
    .plug_and_play_main {
        flex-wrap: wrap;
    }

    .plug_and_play_left {
        width: 100%;
    }

    .plug_and_play_right {
        width: 100%;
    }

    .plug_and_play_submit {
        width: 100%;
        text-align: center;
    }
}
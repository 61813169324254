.email_share {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}


.email_share__container {
    min-height: 80%;
    position: fixed;
    font-size: 16px;
    background-color: white;
    width: 45em;
    height: 60vh;
    border-radius: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'roboto';
    padding: 16px 80px;
    top: -100%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: slideIn 0.5s forwards, fadeInOut 5s infinite alternate;
}

.email_share__form {
    width: 100%;
}

.email_share__form input[type="text"] {
    width: 100%;
    height: 40px;
    color: #5F5F5F;
    border-style: none;
    border-radius: 2%;
    background: #e2e2e2;
    padding-left: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.email_share__form input[type="text"]:focus{
    
  outline: none;
  border: 1px solid #29781D;
}


.email_share__form input[type="checkbox"] {
    min-width: 30px;
    min-height: 30px;
    margin-right: 16px;
}

.email_share__form .check_all {
    margin-top: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.email_share__form .check_boxes {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap:1em;
    margin-bottom: 16px;
}

.email_share__form .check_boxes div {
    display: flex;
    align-items: center;
    width: 150px;
}

.email_share .close_btn {

    position: absolute;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    top: 0px;
    right: 0px;
    margin-right: 32px;
    margin-top: 16px;
}

.deactivated_box{
    color: gray;
}

.thank_you_message{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
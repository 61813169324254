.join_iqland_today{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 3rem;
    gap: 1rem;
    background-image: url("/public/images/lan_home/join_iqland_back.png");
    background-position: 0%;
}

@media (max-width: 767.98px) {
    .join_iqland_today{
        padding: 2rem;
    }
}
.co_reports {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
}

.co_reports__body_cell {
    color: #8E8E8E !important;
}

.co_reports__head_cell {
    color: white !important;
}


.co_reports__more_actions {
    color: #626262;
    transition: 0.5s;
}

.co_reports__more_actions:hover {
    color: var(--primary-clr);
    cursor: pointer;
}

.co_reports__search_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.38rem 3rem;
}

.co_reports__search_area h6 {
    color: #171721;
    font-size: 1.25rem;
    font-weight: 600;
}

.co_reports__input_with_button {
    display: flex;
}

.co_reports__input_with_icon input[type="text"] {
    border-radius: 0.3125rem;
    border: 1px solid #DEDEDE;
    background: #FFF;
    height: 2.5rem;
    width: 100%;
    padding: 0.66rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818A;
    transition: 0.5s;
}

.co_reports__input_with_icon input[type="text"]:focus {
    outline: none;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-clr);
}

.co_reports__keyword_search_btn {

    border-radius: 0rem 0.3125rem 0.3125rem 0rem;
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.co_reports__keyword_search_btn:hover {
    background-color: var(--primary-dark-clr);
    cursor: pointer;
}

.share_modal{
    min-width: 30%;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 4rem;
    border-radius: 0.5rem;
}

.share_modal input{
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.share_modal input:focus{
    outline: none;
}

.share_modal__title{
    align-self: flex-start;
    font-size: 1rem;
    font-weight: 500;
}

.share_modal__buttons{
    display: flex;
    gap: 1rem;
}

.share_modal__container{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.share_modal form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.success_purchase {
    color: var(--primary-clr);
}

.cancelled_purchase {
    color: var(--secondary-clr);
}

.success_purchase button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: var(--primary-clr);
    color: #fff;
    cursor: pointer;
}

button.cancel {
    background-color: var(--secondary-clr);
}
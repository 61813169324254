.user_nav_tulsa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4.125rem;
    padding: 0 1.75rem;
    border-style: solid;
    border-color: #82828229;
    background-color: #fff;
    border-width: 0 0 1px 0;
    font-family: 'poppins', sans-serif;
}

.user_nav_tulsa .user_nav__logo_top:hover{
    cursor: pointer;
}

.user_nav_tulsa_logo_area{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.user_nav_tulsa_logo_area .tulsa_logo_text{
    font-weight: 500;
}

.user_nav_tulsa .user_nav__tabs {
    display: flex;
    list-style-type: none;
    gap: 3em;
    margin-bottom: 0;
    padding-left: 0;
}

.user_nav_tulsa .user_nav__tabs li {
    padding-left: 0;
    font-size: 1rem;
    font-weight: 500 !important;
    padding-bottom: 1rem;
    transition: 0.5s;
    border-style: solid;
    border-color: white;
    border-width: 0 0 0.15rem 0;
    margin-bottom: -1rem;
}

.user_nav_tulsa .user_nav__tabs li:hover {
    cursor: pointer;
    color: var(--primary-clr);
    border-style: solid;
    border-color: var(--primary-clr);
    border-width: 0 0 0.15rem 0;

}

.user_nav_tulsa .user_nav__active_tab {
    color: var(--primary-clr);
    border-color: var(--primary-clr) !important;
}

.user_nav_tulsa .user_nav_profile_area {
    position: relative;
}

.user_nav_tulsa .user_nav_profile {
    display: flex;
    align-items: center;
    gap: 0.81rem;

}

.user_nav_tulsa .user_nav_profile img {
    height: 2.5rem;
    flex-shrink: 0;
}

.user_nav_tulsa .user_nav__burger_icon{
    display: none;
}


@media only screen and (max-width: 992px) {
    .user_nav_tulsa .user_nav__tabs{
        display: none;
    }

    .user_nav_tulsa .user_nav__burger_icon{
        display: block;
    }

    .user_nav_tulsa .user_nav__burger_icon:hover{
        cursor: pointer;
    }
}
.missing_input_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}
.missing_input_button{
    background-color: var(--primary-clr);
    transition: 0.5s;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    outline: none;
    border-style: none;
    flex-grow: 0;
    width: 10ch;
    align-self: flex-end;
}

.missing_input_button:hover{
    background-color: var(--primary-dark-clr);
}
.view_plan {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.view_plan__container {
    position: fixed;
    max-width: 80%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--primary-clr);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}

.view_plan__slides_container{
    overflow-y: scroll;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
}


.view_plan__image_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.view_plan__container .iqland_icon {
    position: absolute;
    width: 2%;
    cursor: pointer;
}

.view_plan__buttons {
    width: 70%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Full screen size */
.part_image_popup{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Actual part popup area */
.part_image_popup__container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 45%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.part_image_popup__container img{
    border-radius: 10px;
}

/* .part_image_popup__container h4{
    background-color: var(--secondary-clr);
    margin-bottom: 0;
    text-align: center;
    font-weight: 900;
    padding: 8px 0;
    border-radius: 10px 10px 0 0 ;
    color: var(--text-clr);
} */